
import React from 'react'



const WhatsNew  =()=> {




    return (
        <div className='cryptoex'>
      <section className="pt-100 secureof">
         <div className='gray-bg'>
            <div className="container">
            <h3 className="heading-h2 text-center">Whats <span className='bluecolor'>new?</span></h3>
            <div className="row ">
                <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
                    <div class="custom-card">
                            <h3>White Label Opensea Clone Development</h3>
                            <p className='pharagraph'>We offer a top-tier White Label OpenSea Clone development service, allowing you to launch your own NFT marketplace with ease. Our solution provides a fully customizable and scalable platform that mirrors OpenSea’s features, enabling you to cater to your unique business needs. With advanced Web3 integration and a user-friendly interface, our clone is designed to provide a seamless NFT trading experience. Benefit from our budget-friendly development process and quick deployment to establish your exclusive marketplace efficiently. </p>
                    </div>
                    <div class="custom-card">
                            <h3>Opensea Clone App Development</h3>
                            <p className='pharagraph'>We offer best-in-class OpenSea Clone app development along with clone services. Many traders now prefer mobile NFT trading platforms. With our OpenSea clone app development service, you can launch a similar NFT trading mobile app compatible with Android and iOS. The OpenSea clone app also works on the reputed Ethereum blockchain network and the app's framework is designed using the latest technology stacks. By launching a superfine NFT Marketplace app with the help of Coinsclone, you can attract many global traders and creators in a short time.</p>
                    </div>
                </div>
            </div>
            </div>
         </div>
      </section>
      </div>
    )
  }


export default WhatsNew