import React from 'react'



const VariousBlock =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h4 className="heading-h2 text-center"><span className="bluecolor heading-h3">Cross Chain Compatibility</span> Of Our Opensea Clone</h4>
          <p className="text-center">Our OpenSea-like NFT Marketplace can be integrated with the most popular blockchain networks. Our expert group of developers has ensured the software we offer is compatible with multiple blockchains in the Crypto industry.</p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/tron-token.png" alt="Tron" /><span>Tron</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance-smart-chain.png" alt="Binance Smart Chain" /><span>Binance Smart Chain</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon" /><span>Polygon</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana" /><span>Solana</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/wave.png" alt="Waves" /><span>Waves</span></p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default VariousBlock